var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"shadow2-a"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"border-right px-0",attrs:{"cols":"6"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"pt-0 d-flex"},[_c('v-btn',{staticClass:"mr-4",attrs:{"fab":"","color":"primary","elevation":"0","small":""},on:{"click":function($event){_vm.addflag = true;
                  _vm.modifydata = {};}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-text-field',{attrs:{"label":"Search","outlined":"","hide-details":"","dense":""}}),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){return _vm.refreshData()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),(_vm.listdata.length > 0)?_c('v-list',{attrs:{"two-line":"","elevation":"0"}},[_vm._l((_vm.listdata),function(v,k){return [_c('v-list-item',{key:k,staticClass:"px-0 border-hover left",attrs:{"link":""},on:{"click":function($event){_vm.selectedindex = k;
                    _vm.modifydata = { ...v };
                    _vm.errors = {};}}},[_c('v-list-item-content',{staticClass:"pl-4"},[_c('v-list-item-title',[_vm._v(" "+_vm._s((v.type_detail || {}).name)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$nova.formatDate(v.from))+" - "+_vm._s(_vm.$nova.formatDate(v.to)))])],1),_c('v-list-item-action')],1)]})],2):_c('div',[_c('v-alert',{attrs:{"type":"info","value":true,"text":"","border":"left"}},[_vm._v(" Add new "+_vm._s(_vm.pageprops.name)+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.addflag || _vm.editflag)?_c('div',[_c('div',{staticClass:"pb-4 d-flex title align-center"},[(_vm.addflag)?_c('span',{},[_vm._v("Add New "+_vm._s(_vm.pageprops.name))]):(_vm.editflag)?_c('span',{},[_vm._v("Edit "+_vm._s(_vm.pageprops.name)+" Details")]):_vm._e(),_c('v-spacer')],1),_c('lb-dropdown',{attrs:{"label":"Client Name","error":_vm.errors['client'],"items":_vm.selectdata.client,"itemtext":"name","itemvalue":"_id"},model:{value:(_vm.modifydata['client']),callback:function ($$v) {_vm.$set(_vm.modifydata, 'client', $$v)},expression:"modifydata['client']"}}),_c('lb-dropdown',{attrs:{"label":"Type","error":_vm.errors['type'],"items":_vm.selectdata.type},model:{value:(_vm.modifydata['type']),callback:function ($$v) {_vm.$set(_vm.modifydata, 'type', $$v)},expression:"modifydata['type']"}}),_c('div',{staticClass:"d-flex"},[_c('lb-date',{attrs:{"label":"From Date","error":_vm.errors['from']},model:{value:(_vm.modifydata['from']),callback:function ($$v) {_vm.$set(_vm.modifydata, 'from', $$v)},expression:"modifydata['from']"}}),_c('span',{staticClass:"mr-4"}),_c('lb-date',{attrs:{"label":"To Date","error":_vm.errors['to']},model:{value:(_vm.modifydata['to']),callback:function ($$v) {_vm.$set(_vm.modifydata, 'to', $$v)},expression:"modifydata['to']"}})],1),_c('div',{staticClass:"text-center pb-4"},[_c('v-btn',{staticClass:"mr-2",attrs:{"text":""},on:{"click":function($event){_vm.addflag = false;
                  _vm.editflag = false;
                  _vm.modifydata =
                    _vm.listdata.length >= _vm.selectedindex + 1 &&
                    _vm.listdata.length > 0
                      ? _vm.listdata[_vm.selectedindex]
                      : {};}}},[_vm._v("Cancel")]),(_vm.addflag)?_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){return _vm.save()}}},[_vm._v("Save")]):(_vm.editflag)?_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){return _vm.update()}}},[_vm._v("Update")]):_vm._e()],1)],1):_c('div',[_c('div',{staticClass:"pb-4 d-flex title align-center"},[_c('span',{},[_vm._v("View "+_vm._s(_vm.pageprops.name)+" Details")]),_c('v-spacer'),(
                  _vm.listdata.length >= _vm.selectedindex + 1 && _vm.listdata.length > 0
                )?_c('v-btn',{attrs:{"icon":"","large":""},on:{"click":function($event){_vm.editflag = true;
                  _vm.add = false;}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1),(
                _vm.listdata.length >= _vm.selectedindex + 1 && _vm.listdata.length > 0
              )?_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',[_vm._v("Client Name:")]),_c('td',[_vm._v(_vm._s(_vm.clientName(_vm.modifydata.client)))])]),_c('tr',[_c('td',[_vm._v("Type:")]),_c('td',[_vm._v(_vm._s(_vm.modifydata.type))])]),_c('tr',[_c('td',[_vm._v("From Date:")]),_c('td',[_vm._v(_vm._s(_vm.$nova.formatDate(_vm.modifydata.from)))])]),_c('tr',[_c('td',[_vm._v("To Date:")]),_c('td',[_vm._v(_vm._s(_vm.$nova.formatDate(_vm.modifydata.to)))])])])]},proxy:true}],null,false,2915646233)}):_c('div',[_c('v-alert',{attrs:{"type":"info","value":true,"text":"","border":"left"}},[_vm._v(" No details available ")])],1)],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }