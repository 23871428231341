<template>
  <div>
    <v-card class="shadow2-a">
      <v-card-text>
        <v-row>
          <v-col cols="6" class="border-right px-0">
            <v-card elevation="0">
              <v-card-text class="pt-0 d-flex">
                <v-btn
                  fab
                  color="primary"
                  elevation="0"
                  small
                  class="mr-4"
                  @click="
                    addflag = true;
                    modifydata = {};
                  "
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
                <v-text-field
                  label="Search"
                  outlined
                  hide-details
                  dense
                ></v-text-field>
                <v-btn icon @click="refreshData()" class="ml-2"
                  ><v-icon>mdi-refresh</v-icon></v-btn
                >
              </v-card-text>

              <v-list two-line v-if="listdata.length > 0" elevation="0">
                <template v-for="(v, k) in listdata">
                  <v-list-item
                    :key="k"
                    link
                    class="px-0 border-hover left"
                    @click="
                      selectedindex = k;
                      modifydata = { ...v };
                      errors = {};
                    "
                  >
                    <v-list-item-content class="pl-4">
                      <v-list-item-title>
                        {{ (v.type_detail || {}).name }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >{{ $nova.formatDate(v.from) }} -
                        {{ $nova.formatDate(v.to) }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action> </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
              <div v-else>
                <v-alert type="info" :value="true" text border="left">
                  Add new {{ pageprops.name }}
                </v-alert>
              </div>
            </v-card>
          </v-col>
          <v-col cols="6">
            <div v-if="addflag || editflag">
              <div class="pb-4 d-flex title align-center">
                <span v-if="addflag" class=""
                  >Add New {{ pageprops.name }}</span
                >
                <span v-else-if="editflag" class=""
                  >Edit {{ pageprops.name }} Details</span
                >
                <v-spacer></v-spacer>
              </div>
              <lb-dropdown
                label="Client Name"
                v-model="modifydata['client']"
                :error="errors['client']"
                :items="selectdata.client"
                itemtext="name"
                itemvalue="_id"
              />
              <lb-dropdown
                label="Type"
                v-model="modifydata['type']"
                :error="errors['type']"
                :items="selectdata.type"
              />
              <div class="d-flex">
                <lb-date
                  label="From Date"
                  v-model="modifydata['from']"
                  :error="errors['from']"
                />
                <span class="mr-4"></span>
                <lb-date
                  label="To Date"
                  v-model="modifydata['to']"
                  :error="errors['to']"
                />
              </div>
              <div class="text-center pb-4">
                <v-btn
                  @click="
                    addflag = false;
                    editflag = false;
                    modifydata =
                      listdata.length >= selectedindex + 1 &&
                      listdata.length > 0
                        ? listdata[selectedindex]
                        : {};
                  "
                  text
                  class="mr-2"
                  >Cancel</v-btn
                >
                <v-btn
                  v-if="addflag"
                  color="primary"
                  @click="save()"
                  elevation="0"
                  >Save</v-btn
                >
                <v-btn
                  v-else-if="editflag"
                  color="primary"
                  @click="update()"
                  elevation="0"
                  >Update</v-btn
                >
              </div>
            </div>
            <div v-else>
              <div class="pb-4 d-flex title align-center">
                <span class="">View {{ pageprops.name }} Details</span>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  large
                  v-if="
                    listdata.length >= selectedindex + 1 && listdata.length > 0
                  "
                  @click="
                    editflag = true;
                    add = false;
                  "
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
              </div>

              <v-simple-table
                dense
                v-if="
                  listdata.length >= selectedindex + 1 && listdata.length > 0
                "
              >
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Client Name:</td>
                      <td>{{ clientName(modifydata.client) }}</td>
                    </tr>
                    <tr>
                      <td>Type:</td>
                      <td>{{ modifydata.type }}</td>
                    </tr>
                    <tr>
                      <td>From Date:</td>
                      <td>{{ $nova.formatDate(modifydata.from) }}</td>
                    </tr>
                    <tr>
                      <td>To Date:</td>
                      <td>{{ $nova.formatDate(modifydata.to) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div v-else>
                <v-alert type="info" :value="true" text border="left">
                  No details available
                </v-alert>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: "engagementv2",
  data: function () {
    return {
      pageprops: {
        name: "Engagement",
        breadcrumbs: [],
      },
      listdata: [],
      addflag: false,
      editflag: false,
      selectedindex: 0,
      modifydata: {},
      errors: {},
      selectdata: {
        type: ["Internal Audit"],
        client: [],
      },
    };
  },
  // components: {
  //   // pageHeader,managelist
  // },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  mounted() {},
  methods: {
    refreshData() {
      if (this.$store.state.client !== "") {
        this.errors = {};
        this.addflag = false;
        this.editflag = false;
        this.getData();
      }
    },
    getData() {
      this.axios
        .post("/v2/engagement/clientlist")
        .then((dt) => {
          this.selectdata.client = [];
          if (dt.data.status === "success" && dt.data.data.length > 0) {
            this.selectdata.client = dt.data.data;
          }
          return this.axios.post("/v2/engagement/get");
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.listdata = dt.data.data;
            if (this.listdata.length > 0);
            if (Object.keys(this.modifydata).length === 0)
              this.modifydata = {
                ...(this.listdata.length > 0 ? this.listdata[0] : {}),
              };
          } else {
            console.log("Error fetching data");
          }
        });
    },
    save() {
      this.axios
        .post("/v2/engagement/add", { data: [this.modifydata] })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.refreshData();
            this.$store.commit("refreshClient", true);
          } else {
            this.errors = {};
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            }
            console.log("Error adding client");
          }
        });
    },
    update() {
      if (this.modifydata._id) {
        this.axios
          .post("/v2/engagement/edit", {
            data: [this.modifydata],
            id: this.modifydata._id,
          })
          .then((dt) => {
            if (dt.data.status === "success") {
              this.refreshData();
              this.$store.commit("refreshClient", true);
            } else {
              this.errors = {};
              if (dt.data.data.length > 0) {
                if (dt.data.data[0].index0)
                  this.errors = dt.data.data[0].index0;
              }
              console.log("Error updating client");
            }
          });
      } else console.log("No ID found to update");
    },
    clientName: function (d) {
      let n = "";

    for (const i of this.selectdata.client) {
       if (i._id === d) {
          n = i.name;
          break;
        }
    }
     return n;
    },
  },
  computed: {},
  watch: {
    "$store.state.client": function () {
      this.selectedindex = -1;
      this.modifydata = {};
      this.addflag = false;
      this.editflag = false;
      this.refreshData();
    },
  },
};
</script>
